import React, { Component } from 'react';
import { render } from 'react-dom';
import './App.css';

class App extends Component {
  constructor() {
    super();
    this.state = {
      search: '',
      copied: false
    };
  }

  copyCodeToClipboard = (v) => {
      navigator.clipboard.writeText(v)
      this.setState({copied: true})
  }

  handleChange = (e) => {
    this.setState({search: e.target.value});
  }

  render() {
    let { icons } = this.props;
    let icon = this.state.search.trim().toLowerCase();
    if (icon.length > 0) {
      icons = icons.filter(val => val.toLowerCase().match(icon));
    }
    return (
      <div>

        <div className="header">
            <h1>Elementor Icons Finder</h1>
            <input
              value={this.state.search}
              onChange={this.handleChange}
              type="text"
              placeholder="Search Icons" />
        </div>
        <div className="icons-wrap">
            <ul className="icons-list">
            {icons.map(icon =>
                // <li key={icon}><i className={`la la-${icon}`}></i> {icon}
                <li key={icon}><i className={`${icon}`}></i> {icon}
                <button onClick={() => this.copyCodeToClipboard(icon)}>
                Copy
              </button>
                </li>
            )}
            </ul>
        </div>

      </div>
    );
  }
}

// let icons = [
//     "500px",
// "accessible-icon",
// "accusoft",
// "acquisitions-incorporated",
// "ad",
// "address-book",
// "address-card",
// "adjust",
// "adn",
// "adobe",
// "adversal",
// "affiliatetheme",
// "air-freshener",
// "airbnb",
// "algolia",
// "align-center",
// "align-justify",
// "align-left",
// "align-right",
// "alipay",
// "allergies",
// "amazon",
// "amazon-pay",
// "ambulance",
// "american-sign-language-interpreting",
// "amilia",
// "anchor",
// "android",
// "angellist",
// "angle-double-down",
// "angle-double-left",
// "angle-double-right",
// "angle-double-up",
// "angle-down",
// "angle-left",
// "angle-right",
// "angle-up",
// "angry",
// "angrycreative",
// "angular",
// "ankh",
// "app-store",
// "app-store-ios",
// "apper",
// "apple",
// "apple-alt",
// "apple-pay",
// "archive",
// "archway",
// "arrow-alt-circle-down",
// "arrow-alt-circle-left",
// "arrow-alt-circle-right",
// "arrow-alt-circle-up",
// "arrow-circle-down",
// "arrow-circle-left",
// "arrow-circle-right",
// "arrow-circle-up",
// "arrow-down",
// "arrow-left",
// "arrow-right",
// "arrow-up",
// "arrows-alt",
// "arrows-alt-h",
// "arrows-alt-v",
// "artstation",
// "assistive-listening-systems",
// "asterisk",
// "asymmetrik",
// "at",
// "atlas",
// "atlassian",
// "atom",
// "audible",
// "audio-description",
// "autoprefixer",
// "avianex",
// "aviato",
// "award",
// "aws",
// "baby",
// "baby-carriage",
// "backspace",
// "backward",
// "bacon",
// "balance-scale",
// "balance-scale-left",
// "balance-scale-right",
// "ban",
// "band-aid",
// "bandcamp",
// "barcode",
// "bars",
// "baseball-ball",
// "basketball-ball",
// "bath",
// "battery-empty",
// "battery-full",
// "battery-half",
// "battery-quarter",
// "battery-three-quarters",
// "battle-net",
// "bed",
// "beer",
// "behance",
// "behance-square",
// "bell",
// "bell-slash",
// "bezier-curve",
// "bible",
// "bicycle",
// "biking",
// "bimobject",
// "binoculars",
// "biohazard",
// "birthday-cake",
// "bitbucket",
// "bitcoin",
// "bity",
// "black-tie",
// "blackberry",
// "blender",
// "blender-phone",
// "blind",
// "blog",
// "blogger",
// "blogger-b",
// "bluetooth",
// "bluetooth-b",
// "bold",
// "bolt",
// "bomb",
// "bone",
// "bong",
// "book",
// "book-dead",
// "book-medical",
// "book-open",
// "book-reader",
// "bookmark",
// "bootstrap",
// "border-all",
// "border-none",
// "border-style",
// "bowling-ball",
// "box",
// "box-open",
// "boxes",
// "braille",
// "brain",
// "bread-slice",
// "briefcase",
// "briefcase-medical",
// "broadcast-tower",
// "broom",
// "brush",
// "btc",
// "buffer",
// "bug",
// "building",
// "bullhorn",
// "bullseye",
// "burn",
// "buromobelexperte",
// "bus",
// "bus-alt",
// "business-time",
// "buy-n-large",
// "buysellads",
// "calculator",
// "calendar",
// "calendar-alt",
// "calendar-check",
// "calendar-day",
// "calendar-minus",
// "calendar-plus",
// "calendar-times",
// "calendar-week",
// "camera",
// "camera-retro",
// "campground",
// "canadian-maple-leaf",
// "candy-cane",
// "cannabis",
// "capsules",
// "car",
// "car-alt",
// "car-battery",
// "car-crash",
// "car-side",
// "caret-down",
// "caret-left",
// "caret-right",
// "caret-square-down",
// "caret-square-left",
// "caret-square-right",
// "caret-square-up",
// "caret-up",
// "carrot",
// "cart-arrow-down",
// "cart-plus",
// "cash-register",
// "cat",
// "cc-amazon-pay",
// "cc-amex",
// "cc-apple-pay",
// "cc-diners-club",
// "cc-discover",
// "cc-jcb",
// "cc-mastercard",
// "cc-paypal",
// "cc-stripe",
// "cc-visa",
// "centercode",
// "centos",
// "certificate",
// "chair",
// "chalkboard",
// "chalkboard-teacher",
// "charging-station",
// "chart-area",
// "chart-bar",
// "chart-line",
// "chart-pie",
// "check",
// "check-circle",
// "check-double",
// "check-square",
// "cheese",
// "chess",
// "chess-bishop",
// "chess-board",
// "chess-king",
// "chess-knight",
// "chess-pawn",
// "chess-queen",
// "chess-rook",
// "chevron-circle-down",
// "chevron-circle-left",
// "chevron-circle-right",
// "chevron-circle-up",
// "chevron-down",
// "chevron-left",
// "chevron-right",
// "chevron-up",
// "child",
// "chrome",
// "chromecast",
// "church",
// "circle",
// "circle-notch",
// "city",
// "clinic-medical",
// "clipboard",
// "clipboard-check",
// "clipboard-list",
// "clock",
// "clone",
// "closed-captioning",
// "cloud",
// "cloud-download-alt",
// "cloud-meatball",
// "cloud-moon",
// "cloud-moon-rain",
// "cloud-rain",
// "cloud-showers-heavy",
// "cloud-sun",
// "cloud-sun-rain",
// "cloud-upload-alt",
// "cloudscale",
// "cloudsmith",
// "cloudversify",
// "cocktail",
// "code",
// "code-branch",
// "codepen",
// "codiepie",
// "coffee",
// "cog",
// "cogs",
// "coins",
// "columns",
// "comment",
// "comment-alt",
// "comment-dollar",
// "comment-dots",
// "comment-medical",
// "comment-slash",
// "comments",
// "comments-dollar",
// "compact-disc",
// "compass",
// "compress",
// "compress-arrows-alt",
// "concierge-bell",
// "confluence",
// "connectdevelop",
// "contao",
// "cookie",
// "cookie-bite",
// "copy",
// "copyright",
// "cotton-bureau",
// "couch",
// "cpanel",
// "creative-commons",
// "creative-commons-by",
// "creative-commons-nc",
// "creative-commons-nc-eu",
// "creative-commons-nc-jp",
// "creative-commons-nd",
// "creative-commons-pd",
// "creative-commons-pd-alt",
// "creative-commons-remix",
// "creative-commons-sa",
// "creative-commons-sampling",
// "creative-commons-sampling-plus",
// "creative-commons-share",
// "creative-commons-zero",
// "credit-card",
// "critical-role",
// "crop",
// "crop-alt",
// "cross",
// "crosshairs",
// "crow",
// "crown",
// "crutch",
// "css3",
// "css3-alt",
// "cube",
// "cubes",
// "cut",
// "cuttlefish",
// "d-and-d",
// "d-and-d-beyond",
// "dashcube",
// "database",
// "deaf",
// "delicious",
// "democrat",
// "deploydog",
// "deskpro",
// "desktop",
// "dev",
// "deviantart",
// "dharmachakra",
// "dhl",
// "diagnoses",
// "diaspora",
// "dice",
// "dice-d20",
// "dice-d6",
// "dice-five",
// "dice-four",
// "dice-one",
// "dice-six",
// "dice-three",
// "dice-two",
// "digg",
// "digital-ocean",
// "digital-tachograph",
// "directions",
// "discord",
// "discourse",
// "divide",
// "dizzy",
// "dna",
// "dochub",
// "docker",
// "dog",
// "dollar-sign",
// "dolly",
// "dolly-flatbed",
// "donate",
// "door-closed",
// "door-open",
// "dot-circle",
// "dove",
// "download",
// "draft2digital",
// "drafting-compass",
// "dragon",
// "draw-polygon",
// "dribbble",
// "dribbble-square",
// "dropbox",
// "drum",
// "drum-steelpan",
// "drumstick-bite",
// "drupal",
// "dumbbell",
// "dumpster",
// "dumpster-fire",
// "dungeon",
// "dyalog",
// "earlybirds",
// "ebay",
// "edge",
// "edit",
// "egg",
// "eject",
// "elementor",
// "ellipsis-h",
// "ellipsis-v",
// "ello",
// "ember",
// "empire",
// "envelope",
// "envelope-open",
// "envelope-open-text",
// "envelope-square",
// "envira",
// "equals",
// "eraser",
// "erlang",
// "ethereum",
// "ethernet",
// "etsy",
// "euro-sign",
// "evernote",
// "exchange-alt",
// "exclamation",
// "exclamation-circle",
// "exclamation-triangle",
// "expand",
// "expand-arrows-alt",
// "expeditedssl",
// "external-link-alt",
// "external-link-square-alt",
// "eye",
// "eye-dropper",
// "eye-slash",
// "facebook",
// "facebook-f",
// "facebook-messenger",
// "facebook-square",
// "fan",
// "fantasy-flight-games",
// "fast-backward",
// "fast-forward",
// "fax",
// "feather",
// "feather-alt",
// "fedex",
// "fedora",
// "female",
// "fighter-jet",
// "figma",
// "file",
// "file-alt",
// "file-archive",
// "file-audio",
// "file-code",
// "file-contract",
// "file-csv",
// "file-download",
// "file-excel",
// "file-export",
// "file-image",
// "file-import",
// "file-invoice",
// "file-invoice-dollar",
// "file-medical",
// "file-medical-alt",
// "file-pdf",
// "file-powerpoint",
// "file-prescription",
// "file-signature",
// "file-upload",
// "file-video",
// "file-word",
// "fill",
// "fill-drip",
// "film",
// "filter",
// "fingerprint",
// "fire",
// "fire-alt",
// "fire-extinguisher",
// "firefox",
// "first-aid",
// "first-order",
// "first-order-alt",
// "firstdraft",
// "fish",
// "fist-raised",
// "flag",
// "flag-checkered",
// "flag-usa",
// "flask",
// "flickr",
// "flipboard",
// "flushed",
// "fly",
// "folder",
// "folder-minus",
// "folder-open",
// "folder-plus",
// "font",
// "font-awesome",
// "font-awesome-alt",
// "font-awesome-flag",
// "font-awesome-logo-full",
// "fonticons",
// "fonticons-fi",
// "football-ball",
// "fort-awesome",
// "fort-awesome-alt",
// "forumbee",
// "forward",
// "foursquare",
// "free-code-camp",
// "freebsd",
// "frog",
// "frown",
// "frown-open",
// "fulcrum",
// "funnel-dollar",
// "futbol",
// "galactic-republic",
// "galactic-senate",
// "gamepad",
// "gas-pump",
// "gavel",
// "gem",
// "genderless",
// "get-pocket",
// "gg",
// "gg-circle",
// "ghost",
// "gift",
// "gifts",
// "git",
// "git-alt",
// "git-square",
// "github",
// "github-alt",
// "github-square",
// "gitkraken",
// "gitlab",
// "gitter",
// "glass-cheers",
// "glass-martini",
// "glass-martini-alt",
// "glass-whiskey",
// "glasses",
// "glide",
// "glide-g",
// "globe",
// "globe-africa",
// "globe-americas",
// "globe-asia",
// "globe-europe",
// "gofore",
// "golf-ball",
// "goodreads",
// "goodreads-g",
// "google",
// "google-drive",
// "google-play",
// "google-plus",
// "google-plus-g",
// "google-plus-square",
// "google-wallet",
// "gopuram",
// "graduation-cap",
// "gratipay",
// "grav",
// "greater-than",
// "greater-than-equal",
// "grimace",
// "grin",
// "grin-alt",
// "grin-beam",
// "grin-beam-sweat",
// "grin-hearts",
// "grin-squint",
// "grin-squint-tears",
// "grin-stars",
// "grin-tears",
// "grin-tongue",
// "grin-tongue-squint",
// "grin-tongue-wink",
// "grin-wink",
// "grip-horizontal",
// "grip-lines",
// "grip-lines-vertical",
// "grip-vertical",
// "gripfire",
// "grunt",
// "guitar",
// "gulp",
// "h-square",
// "hacker-news",
// "hacker-news-square",
// "hackerrank",
// "hamburger",
// "hammer",
// "hamsa",
// "hand-holding",
// "hand-holding-heart",
// "hand-holding-usd",
// "hand-lizard",
// "hand-middle-finger",
// "hand-paper",
// "hand-peace",
// "hand-point-down",
// "hand-point-left",
// "hand-point-right",
// "hand-point-up",
// "hand-pointer",
// "hand-rock",
// "hand-scissors",
// "hand-spock",
// "hands",
// "hands-helping",
// "handshake",
// "hanukiah",
// "hard-hat",
// "hashtag",
// "hat-cowboy",
// "hat-cowboy-side",
// "hat-wizard",
// "haykal",
// "hdd",
// "heading",
// "headphones",
// "headphones-alt",
// "headset",
// "heart",
// "heart-broken",
// "heartbeat",
// "helicopter",
// "highlighter",
// "hiking",
// "hippo",
// "hips",
// "hire-a-helper",
// "history",
// "hockey-puck",
// "holly-berry",
// "home",
// "hooli",
// "hornbill",
// "horse",
// "horse-head",
// "hospital",
// "hospital-alt",
// "hospital-symbol",
// "hot-tub",
// "hotdog",
// "hotel",
// "hotjar",
// "hourglass",
// "hourglass-end",
// "hourglass-half",
// "hourglass-start",
// "house-damage",
// "houzz",
// "hryvnia",
// "html5",
// "hubspot",
// "i-cursor",
// "ice-cream",
// "icicles",
// "icons",
// "id-badge",
// "id-card",
// "id-card-alt",
// "igloo",
// "image",
// "images",
// "imdb",
// "inbox",
// "indent",
// "industry",
// "infinity",
// "info",
// "info-circle",
// "instagram",
// "intercom",
// "internet-explorer",
// "invision",
// "ioxhost",
// "italic",
// "itch-io",
// "itunes",
// "itunes-note",
// "java",
// "jedi",
// "jedi-order",
// "jenkins",
// "jira",
// "joget",
// "joint",
// "joomla",
// "journal-whills",
// "js",
// "js-square",
// "jsfiddle",
// "kaaba",
// "kaggle",
// "key",
// "keybase",
// "keyboard",
// "keycdn",
// "khanda",
// "kickstarter",
// "kickstarter-k",
// "kiss",
// "kiss-beam",
// "kiss-wink-heart",
// "kiwi-bird",
// "korvue",
// "landmark",
// "language",
// "laptop",
// "laptop-code",
// "laptop-medical",
// "laravel",
// "lastfm",
// "lastfm-square",
// "laugh",
// "laugh-beam",
// "laugh-squint",
// "laugh-wink",
// "layer-group",
// "leaf",
// "leanpub",
// "lemon",
// "less",
// "less-than",
// "less-than-equal",
// "level-down-alt",
// "level-up-alt",
// "life-ring",
// "lightbulb",
// "line",
// "link",
// "linkedin",
// "linkedin-in",
// "linode",
// "linux",
// "lira-sign",
// "list",
// "list-alt",
// "list-ol",
// "list-ul",
// "location-arrow",
// "lock",
// "lock-open",
// "long-arrow-alt-down",
// "long-arrow-alt-left",
// "long-arrow-alt-right",
// "long-arrow-alt-up",
// "low-vision",
// "luggage-cart",
// "lyft",
// "magento",
// "magic",
// "magnet",
// "mail-bulk",
// "mailchimp",
// "male",
// "mandalorian",
// "map",
// "map-marked",
// "map-marked-alt",
// "map-marker",
// "map-marker-alt",
// "map-pin",
// "map-signs",
// "markdown",
// "marker",
// "mars",
// "mars-double",
// "mars-stroke",
// "mars-stroke-h",
// "mars-stroke-v",
// "mask",
// "mastodon",
// "maxcdn",
// "mdb",
// "medal",
// "medapps",
// "medium",
// "medium-m",
// "medkit",
// "medrt",
// "meetup",
// "megaport",
// "meh",
// "meh-blank",
// "meh-rolling-eyes",
// "memory",
// "mendeley",
// "menorah",
// "mercury",
// "meteor",
// "microchip",
// "microphone",
// "microphone-alt",
// "microphone-alt-slash",
// "microphone-slash",
// "microscope",
// "microsoft",
// "minus",
// "minus-circle",
// "minus-square",
// "mitten",
// "mix",
// "mixcloud",
// "mizuni",
// "mobile",
// "mobile-alt",
// "modx",
// "monero",
// "money-bill",
// "money-bill-alt",
// "money-bill-wave",
// "money-bill-wave-alt",
// "money-check",
// "money-check-alt",
// "monument",
// "moon",
// "mortar-pestle",
// "mosque",
// "motorcycle",
// "mountain",
// "mouse",
// "mouse-pointer",
// "mug-hot",
// "music",
// "napster",
// "neos",
// "network-wired",
// "neuter",
// "newspaper",
// "nimblr",
// "node",
// "node-js",
// "not-equal",
// "notes-medical",
// "npm",
// "ns8",
// "nutritionix",
// "object-group",
// "object-ungroup",
// "odnoklassniki",
// "odnoklassniki-square",
// "oil-can",
// "old-republic",
// "om",
// "opencart",
// "openid",
// "opera",
// "optin-monster",
// "orcid",
// "osi",
// "otter",
// "outdent",
// "page4",
// "pagelines",
// "pager",
// "paint-brush",
// "paint-roller",
// "palette",
// "palfed",
// "pallet",
// "paper-plane",
// "paperclip",
// "parachute-box",
// "paragraph",
// "parking",
// "passport",
// "pastafarianism",
// "paste",
// "patreon",
// "pause",
// "pause-circle",
// "paw",
// "paypal",
// "peace",
// "pen",
// "pen-alt",
// "pen-fancy",
// "pen-nib",
// "pen-square",
// "pencil-alt",
// "pencil-ruler",
// "penny-arcade",
// "people-carry",
// "pepper-hot",
// "percent",
// "percentage",
// "periscope",
// "person-booth",
// "phabricator",
// "phoenix-framework",
// "phoenix-squadron",
// "phone",
// "phone-alt",
// "phone-slash",
// "phone-square",
// "phone-square-alt",
// "phone-volume",
// "photo-video",
// "php",
// "pied-piper",
// "pied-piper-alt",
// "pied-piper-hat",
// "pied-piper-pp",
// "piggy-bank",
// "pills",
// "pinterest",
// "pinterest-p",
// "pinterest-square",
// "pizza-slice",
// "place-of-worship",
// "plane",
// "plane-arrival",
// "plane-departure",
// "play",
// "play-circle",
// "playstation",
// "plug",
// "plus",
// "plus-circle",
// "plus-square",
// "podcast",
// "poll",
// "poll-h",
// "poo",
// "poo-storm",
// "poop",
// "portrait",
// "pound-sign",
// "power-off",
// "pray",
// "praying-hands",
// "prescription",
// "prescription-bottle",
// "prescription-bottle-alt",
// "print",
// "procedures",
// "product-hunt",
// "project-diagram",
// "pushed",
// "puzzle-piece",
// "python",
// "qq",
// "qrcode",
// "question",
// "question-circle",
// "quidditch",
// "quinscape",
// "quora",
// "quote-left",
// "quote-right",
// "quran",
// "r-project",
// "radiation",
// "radiation-alt",
// "rainbow",
// "random",
// "raspberry-pi",
// "ravelry",
// "react",
// "reacteurope",
// "readme",
// "rebel",
// "receipt",
// "record-vinyl",
// "recycle",
// "red-river",
// "reddit",
// "reddit-alien",
// "reddit-square",
// "redhat",
// "redo",
// "redo-alt",
// "registered",
// "remove-format",
// "renren",
// "reply",
// "reply-all",
// "replyd",
// "republican",
// "researchgate",
// "resolving",
// "restroom",
// "retweet",
// "rev",
// "ribbon",
// "ring",
// "road",
// "robot",
// "rocket",
// "rocketchat",
// "rockrms",
// "route",
// "rss",
// "rss-square",
// "ruble-sign",
// "ruler",
// "ruler-combined",
// "ruler-horizontal",
// "ruler-vertical",
// "running",
// "rupee-sign",
// "sad-cry",
// "sad-tear",
// "safari",
// "salesforce",
// "sass",
// "satellite",
// "satellite-dish",
// "save",
// "schlix",
// "school",
// "screwdriver",
// "scribd",
// "scroll",
// "sd-card",
// "search",
// "search-dollar",
// "search-location",
// "search-minus",
// "search-plus",
// "searchengin",
// "seedling",
// "sellcast",
// "sellsy",
// "server",
// "servicestack",
// "shapes",
// "share",
// "share-alt",
// "share-alt-square",
// "share-square",
// "shekel-sign",
// "shield-alt",
// "ship",
// "shipping-fast",
// "shirtsinbulk",
// "shoe-prints",
// "shopping-bag",
// "shopping-basket",
// "shopping-cart",
// "shopware",
// "shower",
// "shuttle-van",
// "sign",
// "sign-in-alt",
// "sign-language",
// "sign-out-alt",
// "signal",
// "signature",
// "sim-card",
// "simplybuilt",
// "sistrix",
// "sitemap",
// "sith",
// "skating",
// "sketch",
// "skiing",
// "skiing-nordic",
// "skull",
// "skull-crossbones",
// "skyatlas",
// "skype",
// "slack",
// "slack-hash",
// "slash",
// "sleigh",
// "sliders-h",
// "slideshare",
// "smile",
// "smile-beam",
// "smile-wink",
// "smog",
// "smoking",
// "smoking-ban",
// "sms",
// "snapchat",
// "snapchat-ghost",
// "snapchat-square",
// "snowboarding",
// "snowflake",
// "snowman",
// "snowplow",
// "socks",
// "solar-panel",
// "sort",
// "sort-alpha-down",
// "sort-alpha-down-alt",
// "sort-alpha-up",
// "sort-alpha-up-alt",
// "sort-amount-down",
// "sort-amount-down-alt",
// "sort-amount-up",
// "sort-amount-up-alt",
// "sort-down",
// "sort-numeric-down",
// "sort-numeric-down-alt",
// "sort-numeric-up",
// "sort-numeric-up-alt",
// "sort-up",
// "soundcloud",
// "sourcetree",
// "spa",
// "space-shuttle",
// "speakap",
// "speaker-deck",
// "spell-check",
// "spider",
// "spinner",
// "splotch",
// "spotify",
// "spray-can",
// "square",
// "square-full",
// "square-root-alt",
// "squarespace",
// "stack-exchange",
// "stack-overflow",
// "stackpath",
// "stamp",
// "star",
// "star-and-crescent",
// "star-half",
// "star-half-alt",
// "star-of-david",
// "star-of-life",
// "staylinked",
// "steam",
// "steam-square",
// "steam-symbol",
// "step-backward",
// "step-forward",
// "stethoscope",
// "sticker-mule",
// "sticky-note",
// "stop",
// "stop-circle",
// "stopwatch",
// "store",
// "store-alt",
// "strava",
// "stream",
// "street-view",
// "strikethrough",
// "stripe",
// "stripe-s",
// "stroopwafel",
// "studiovinari",
// "stumbleupon",
// "stumbleupon-circle",
// "subscript",
// "subway",
// "suitcase",
// "suitcase-rolling",
// "sun",
// "superpowers",
// "superscript",
// "supple",
// "surprise",
// "suse",
// "swatchbook",
// "swift",
// "swimmer",
// "swimming-pool",
// "symfony",
// "synagogue",
// "sync",
// "sync-alt",
// "syringe",
// "table",
// "table-tennis",
// "tablet",
// "tablet-alt",
// "tablets",
// "tachometer-alt",
// "tag",
// "tags",
// "tape",
// "tasks",
// "taxi",
// "teamspeak",
// "teeth",
// "teeth-open",
// "telegram",
// "telegram-plane",
// "temperature-high",
// "temperature-low",
// "tencent-weibo",
// "tenge",
// "terminal",
// "text-height",
// "text-width",
// "th",
// "th-large",
// "th-list",
// "the-red-yeti",
// "theater-masks",
// "themeco",
// "themeisle",
// "thermometer-empty",
// "thermometer-full",
// "thermometer-half",
// "thermometer-quarter",
// "thermometer-three-quarters",
// "think-peaks",
// "thumbs-down",
// "thumbs-up",
// "thumbtack",
// "ticket-alt",
// "times",
// "times-circle",
// "tint",
// "tint-slash",
// "tired",
// "toggle-off",
// "toggle-on",
// "toilet",
// "toilet-paper",
// "toolbox",
// "tools",
// "tooth",
// "torah",
// "torii-gate",
// "tractor",
// "trade-federation",
// "trademark",
// "traffic-light",
// "train",
// "tram",
// "transgender",
// "transgender-alt",
// "trash",
// "trash-alt",
// "trash-restore",
// "trash-restore-alt",
// "tree",
// "trello",
// "tripadvisor",
// "trophy",
// "truck",
// "truck-loading",
// "truck-monster",
// "truck-moving",
// "truck-pickup",
// "tshirt",
// "tty",
// "tumblr",
// "tumblr-square",
// "tv",
// "twitch",
// "twitter",
// "twitter-square",
// "typo3",
// "uber",
// "ubuntu",
// "uikit",
// "umbraco",
// "umbrella",
// "umbrella-beach",
// "underline",
// "undo",
// "undo-alt",
// "uniregistry",
// "universal-access",
// "university",
// "unlink",
// "unlock",
// "unlock-alt",
// "untappd",
// "upload",
// "ups",
// "usb",
// "user",
// "user-alt",
// "user-alt-slash",
// "user-astronaut",
// "user-check",
// "user-circle",
// "user-clock",
// "user-cog",
// "user-edit",
// "user-friends",
// "user-graduate",
// "user-injured",
// "user-lock",
// "user-md",
// "user-minus",
// "user-ninja",
// "user-nurse",
// "user-plus",
// "user-secret",
// "user-shield",
// "user-slash",
// "user-tag",
// "user-tie",
// "user-times",
// "users",
// "users-cog",
// "usps",
// "ussunnah",
// "utensil-spoon",
// "utensils",
// "vaadin",
// "vector-square",
// "venus",
// "venus-double",
// "venus-mars",
// "viacoin",
// "viadeo",
// "viadeo-square",
// "vial",
// "vials",
// "viber",
// "video",
// "video-slash",
// "vihara",
// "vimeo",
// "vimeo-square",
// "vimeo-v",
// "vine",
// "vk",
// "vnv",
// "voicemail",
// "volleyball-ball",
// "volume-down",
// "volume-mute",
// "volume-off",
// "volume-up",
// "vote-yea",
// "vr-cardboard",
// "vuejs",
// "walking",
// "wallet",
// "warehouse",
// "water",
// "wave-square",
// "waze",
// "weebly",
// "weibo",
// "weight",
// "weight-hanging",
// "weixin",
// "whatsapp",
// "whatsapp-square",
// "wheelchair",
// "whmcs",
// "wifi",
// "wikipedia-w",
// "wind",
// "window-close",
// "window-maximize",
// "window-minimize",
// "window-restore",
// "windows",
// "wine-bottle",
// "wine-glass",
// "wine-glass-alt",
// "wix",
// "wizards-of-the-coast",
// "wolf-pack-battalion",
// "won-sign",
// "wordpress",
// "wordpress-simple",
// "wpbeginner",
// "wpexplorer",
// "wpforms",
// "wpressr",
// "wrench",
// "x-ray",
// "xbox",
// "xing",
// "xing-square",
// "y-combinator",
// "yahoo",
// "yammer",
// "yandex",
// "yandex-international",
// "yarn",
// "yelp",
// "yen-sign",
// "yin-yang",
// "yoast",
// "youtube",
// "youtube-square",
// "zhihu",
// "glass",
// "star-o",
// "remove",
// "close",
// "gear",
// "trash-o",
// "file-o",
// "clock-o",
// "arrow-circle-o-down",
// "arrow-circle-o-up",
// "play-circle-o",
// "repeat",
// "rotate-right",
// "refresh",
// "dedent",
// "video-camera",
// "picture-o",
// "photo",
// "pencil",
// "pencil-square-o",
// "share-square-o",
// "check-square-o",
// "arrows",
// "times-circle-o",
// "check-circle-o",
// "mail-forward",
// "warning",
// "arrows-v",
// "arrows-h",
// "bar-chart",
// "bar-chart-o",
// "gears",
// "thumbs-o-up",
// "thumbs-o-down",
// "heart-o",
// "sign-out",
// "linkedin-square",
// "thumb-tack",
// "external-link",
// "sign-in",
// "lemon-o",
// "square-o",
// "bookmark-o",
// "feed",
// "hdd-o",
// "hand-o-right",
// "hand-o-left",
// "hand-o-up",
// "hand-o-down",
// "group",
// "chain",
// "scissors",
// "files-o",
// "floppy-o",
// "navicon",
// "reorder",
// "money",
// "unsorted",
// "sort-desc",
// "sort-asc",
// "rotate-left",
// "legal",
// "tachometer",
// "dashboard",
// "comment-o",
// "comments-o",
// "flash",
// "lightbulb-o",
// "exchange",
// "cloud-download",
// "cloud-upload",
// "bell-o",
// "cutlery",
// "file-text-o",
// "building-o",
// "hospital-o",
// "mobile-phone",
// "circle-o",
// "mail-reply",
// "folder-o",
// "folder-open-o",
// "smile-o",
// "frown-o",
// "meh-o",
// "keyboard-o",
// "flag-o",
// "mail-reply-all",
// "star-half-o",
// "star-half-empty",
// "star-half-full",
// "code-fork",
// "chain-broken",
// "shield",
// "calendar-o",
// "ticket",
// "minus-square-o",
// "level-up",
// "level-down",
// "pencil-square",
// "external-link-square",
// "caret-square-o-down",
// "toggle-down",
// "caret-square-o-up",
// "toggle-up",
// "caret-square-o-right",
// "toggle-right",
// "eur",
// "euro",
// "gbp",
// "usd",
// "dollar",
// "inr",
// "rupee",
// "jpy",
// "cny",
// "rmb",
// "yen",
// "rub",
// "ruble",
// "rouble",
// "krw",
// "won",
// "file-text",
// "sort-alpha-asc",
// "sort-alpha-desc",
// "sort-amount-asc",
// "sort-amount-desc",
// "sort-numeric-asc",
// "sort-numeric-desc",
// "youtube-play",
// "bitbucket-square",
// "long-arrow-down",
// "long-arrow-up",
// "long-arrow-left",
// "long-arrow-right",
// "gittip",
// "sun-o",
// "moon-o",
// "arrow-circle-o-right",
// "arrow-circle-o-left",
// "caret-square-o-left",
// "toggle-left",
// "dot-circle-o",
// "try",
// "turkish-lira",
// "plus-square-o",
// "institution",
// "bank",
// "mortar-board",
// "spoon",
// "automobile",
// "cab",
// "envelope-o",
// "file-pdf-o",
// "file-word-o",
// "file-excel-o",
// "file-powerpoint-o",
// "file-image-o",
// "file-photo-o",
// "file-picture-o",
// "file-archive-o",
// "file-zip-o",
// "file-audio-o",
// "file-sound-o",
// "file-video-o",
// "file-movie-o",
// "file-code-o",
// "life-bouy",
// "life-buoy",
// "life-saver",
// "support",
// "circle-o-notch",
// "ra",
// "resistance",
// "ge",
// "y-combinator-square",
// "yc-square",
// "wechat",
// "send",
// "paper-plane-o",
// "send-o",
// "circle-thin",
// "header",
// "sliders",
// "futbol-o",
// "soccer-ball-o",
// "newspaper-o",
// "bell-slash-o",
// "eyedropper",
// "area-chart",
// "pie-chart",
// "line-chart",
// "cc",
// "ils",
// "shekel",
// "sheqel",
// "meanpath",
// "diamond",
// "intersex",
// "facebook-official",
// "yc",
// "battery-4",
// "battery",
// "battery-3",
// "battery-2",
// "battery-1",
// "battery-0",
// "sticky-note-o",
// "hourglass-o",
// "hourglass-1",
// "hourglass-2",
// "hourglass-3",
// "hand-rock-o",
// "hand-grab-o",
// "hand-paper-o",
// "hand-stop-o",
// "hand-scissors-o",
// "hand-lizard-o",
// "hand-spock-o",
// "hand-pointer-o",
// "hand-peace-o",
// "television",
// "calendar-plus-o",
// "calendar-minus-o",
// "calendar-times-o",
// "calendar-check-o",
// "map-o",
// "commenting",
// "commenting-o",
// "credit-card-alt",
// "pause-circle-o",
// "stop-circle-o",
// "wheelchair-alt",
// "question-circle-o",
// "volume-control-phone",
// "asl-interpreting",
// "deafness",
// "hard-of-hearing",
// "signing",
// "google-plus-official",
// "google-plus-circle",
// "fa",
// "handshake-o",
// "envelope-open-o",
// "address-book-o",
// "vcard",
// "address-card-o",
// "vcard-o",
// "user-circle-o",
// "user-o",
// "drivers-license",
// "id-card-o",
// "drivers-license-o",
// "thermometer-4",
// "thermometer",
// "thermometer-3",
// "thermometer-2",
// "thermometer-1",
// "thermometer-0",
// "bathtub",
// "s15",
// "times-rectangle",
// "times-rectangle-o",
// "eercast"
// ]

let icons = [
    "eicon-editor-unlink",
"eicon-editor-external-link",
"eicon-editor-close",
"eicon-editor-list-ol",
"eicon-editor-list-ul",
"eicon-editor-bold",
"eicon-editor-italic",
"eicon-editor-underline",
"eicon-editor-paragraph",
"eicon-editor-h1",
"eicon-editor-h2",
"eicon-editor-h3",
"eicon-editor-h4",
"eicon-editor-h5",
"eicon-editor-h6",
"eicon-editor-quote",
"eicon-editor-code",
"eicon-elementor",
"eicon-elementor-circle",
"eicon-pojome",
"eicon-plus",
"eicon-menu-bar",
"eicon-apps",
"eicon-accordion",
"eicon-alert",
"eicon-animation-text",
"eicon-animation",
"eicon-banner",
"eicon-blockquote",
"eicon-button",
"eicon-call-to-action",
"eicon-captcha",
"eicon-carousel",
"eicon-checkbox",
"eicon-columns",
"eicon-countdown",
"eicon-counter",
"eicon-date",
"eicon-divider-shape",
"eicon-divider",
"eicon-download-button",
"eicon-dual-button",
"eicon-email-field",
"eicon-facebook-comments",
"eicon-facebook-like-box",
"eicon-form-horizontal",
"eicon-form-vertical",
"eicon-gallery-grid",
"eicon-gallery-group",
"eicon-gallery-justified",
"eicon-gallery-masonry",
"eicon-icon-box",
"eicon-image-before-after",
"eicon-image-box",
"eicon-image-hotspot",
"eicon-image-rollover",
"eicon-info-box",
"eicon-inner-section",
"eicon-mailchimp",
"eicon-menu-card",
"eicon-navigation-horizontal",
"eicon-nav-menu",
"eicon-navigation-vertical",
"eicon-number-field",
"eicon-parallax",
"eicon-php7",
"eicon-post-list",
"eicon-post-slider",
"eicon-post",
"eicon-posts-carousel",
"eicon-posts-grid",
"eicon-posts-group",
"eicon-posts-justified",
"eicon-posts-masonry",
"eicon-posts-ticker",
"eicon-price-list",
"eicon-price-table",
"eicon-radio",
"eicon-rtl",
"eicon-scroll",
"eicon-search",
"eicon-select",
"eicon-share",
"eicon-sidebar",
"eicon-skill-bar",
"eicon-slider-3d",
"eicon-slider-album",
"eicon-slider-device",
"eicon-slider-full-screen",
"eicon-slider-push",
"eicon-slider-vertical",
"eicon-slider-video",
"eicon-slides",
"eicon-social-icons",
"eicon-spacer",
"eicon-table",
"eicon-tabs",
"eicon-tel-field",
"eicon-text-area",
"eicon-text-field",
"eicon-thumbnails-down",
"eicon-thumbnails-half",
"eicon-thumbnails-right",
"eicon-time-line",
"eicon-toggle",
"eicon-url",
"eicon-t-letter",
"eicon-wordpress",
"eicon-text",
"eicon-anchor",
"eicon-bullet-list",
"eicon-code",
"eicon-favorite",
"eicon-google-maps",
"eicon-image",
"eicon-photo-library",
"eicon-woocommerce",
"eicon-youtube",
"eicon-flip-box",
"eicon-settings",
"eicon-headphones",
"eicon-testimonial",
"eicon-counter-circle",
"eicon-person",
"eicon-chevron-right",
"eicon-chevron-left",
"eicon-close",
"eicon-file-download",
"eicon-save",
"eicon-zoom-in",
"eicon-shortcode",
"eicon-nerd",
"eicon-device-desktop",
"eicon-device-tablet",
"eicon-device-mobile",
"eicon-document-file",
"eicon-folder-o",
"eicon-hypster",
"eicon-h-align-left",
"eicon-h-align-right",
"eicon-h-align-center",
"eicon-h-align-stretch",
"eicon-v-align-top",
"eicon-v-align-bottom",
"eicon-v-align-middle",
"eicon-v-align-stretch",
"eicon-pro-icon",
"eicon-mail",
"eicon-lock-user",
"eicon-testimonial-carousel",
"eicon-media-carousel",
"eicon-section",
"eicon-column",
"eicon-edit",
"eicon-clone",
"eicon-trash",
"eicon-play",
"eicon-angle-right",
"eicon-angle-left",
"eicon-animated-headline",
"eicon-menu-toggle",
"eicon-fb-embed",
"eicon-fb-feed",
"eicon-twitter-embed",
"eicon-twitter-feed",
"eicon-sync",
"eicon-import-export",
"eicon-check-circle",
"eicon-library-save",
"eicon-library-download",
"eicon-insert",
"eicon-preview-medium",
"eicon-sort-down",
"eicon-sort-up",
"eicon-heading",
"eicon-logo",
"eicon-meta-data",
"eicon-post-content",
"eicon-post-excerpt",
"eicon-post-navigation",
"eicon-yoast",
"eicon-nerd-chuckle",
"eicon-nerd-wink",
"eicon-comments",
"eicon-download-circle-o",
"eicon-library-upload",
"eicon-save-o",
"eicon-upload-circle-o",
"eicon-ellipsis-h",
"eicon-ellipsis-v",
"eicon-arrow-left",
"eicon-arrow-right",
"eicon-arrow-up",
"eicon-arrow-down",
"eicon-play-o",
"eicon-archive-posts",
"eicon-archive-title",
"eicon-featured-image",
"eicon-post-info",
"eicon-post-title",
"eicon-site-logo",
"eicon-site-search",
"eicon-site-title",
"eicon-plus-square",
"eicon-minus-square",
"eicon-cloud-check",
"eicon-drag-n-drop",
"eicon-welcome",
"eicon-handle",
"eicon-cart",
"eicon-product-add-to-cart",
"eicon-product-breadcrumbs",
"eicon-product-categories",
"eicon-product-description",
"eicon-product-images",
"eicon-product-info",
"eicon-product-meta",
"eicon-product-pages",
"eicon-product-price",
"eicon-product-rating",
"eicon-product-related",
"eicon-product-stock",
"eicon-product-tabs",
"eicon-product-title",
"eicon-product-upsell",
"eicon-products",
"eicon-bag-light",
"eicon-bag-medium",
"eicon-bag-solid",
"eicon-basket-light",
"eicon-basket-medium",
"eicon-basket-solid",
"eicon-cart-light",
"eicon-cart-medium",
"eicon-cart-solid",
"eicon-exchange",
"eicon-preview-thin",
"eicon-device-laptop",
"eicon-collapse",
"eicon-expand",
"eicon-navigator",
"eicon-plug",
"eicon-dashboard",
"eicon-typography",
"eicon-info-circle-o",
"eicon-integration",
"eicon-plus-circle-o",
"eicon-rating",
"eicon-review",
"eicon-tools",
"eicon-loading",
"eicon-sitemap",
"eicon-click",
"eicon-clock",
"eicon-library-open",
"eicon-warning",
"eicon-flow",
"eicon-cursor-move",
"eicon-arrow-circle-left",
"eicon-flash",
"eicon-redo",
"eicon-ban",
"eicon-barcode",
"eicon-calendar",
"eicon-caret-left",
"eicon-caret-right",
"eicon-caret-up",
"eicon-chain-broken",
"eicon-check-circle-o",
"eicon-check",
"eicon-chevron-double-left",
"eicon-chevron-double-right",
"eicon-undo",
"eicon-filter",
"eicon-circle-o",
"eicon-circle",
"eicon-clock-o",
"eicon-cog",
"eicon-cogs",
"eicon-commenting-o",
"eicon-copy",
"eicon-database",
"eicon-dot-circle-o",
"eicon-envelope",
"eicon-external-link-square",
"eicon-eyedropper",
"eicon-folder",
"eicon-font",
"eicon-adjust",
"eicon-lightbox",
"eicon-heart-o",
"eicon-history",
"eicon-image-bold",
"eicon-info-circle",
"eicon-link",
"eicon-long-arrow-left",
"eicon-long-arrow-right",
"eicon-caret-down",
"eicon-paint-brush",
"eicon-pencil",
"eicon-plus-circle",
"eicon-zoom-in-bold",
"eicon-sort-amount-desc",
"eicon-sign-out",
"eicon-spinner",
"eicon-square",
"eicon-star-o",
"eicon-star",
"eicon-text-align-justify",
"eicon-text-align-center",
"eicon-tags",
"eicon-text-align-left",
"eicon-text-align-right",
"eicon-close-circle",
"eicon-trash-o",
"eicon-font-awesome",
"eicon-user-circle-o",
"eicon-video-camera",
"eicon-heart",
"eicon-wrench",
"eicon-help",
"eicon-help-o",
"eicon-zoom-out-bold",
"eicon-plus-square-o",
"eicon-minus-square-o",
"eicon-minus-circle",
"eicon-minus-circle-o",
"eicon-code-bold",
"eicon-cloud-upload",
"eicon-search-bold",
"eicon-map-pin",
"eicon-meetup",
"eicon-slideshow",
"eicon-t-letter-bold",
"eicon-preferences",
"eicon-table-of-contents",
"eicon-tv",
"eicon-upload",
"eicon-instagram-comments",
"eicon-instagram-nested-gallery",
"eicon-instagram-post",
"eicon-instagram-video",
"eicon-instagram-gallery",
"eicon-instagram-likes",
"eicon-facebook",
"eicon-twitter",
"eicon-pinterest",
"eicon-frame-expand",
"eicon-frame-minimize",
"eicon-archive",
"eicon-colors-typography",
"eicon-custom",
"eicon-footer",
"eicon-header",
"eicon-layout-settings",
"eicon-lightbox-expand",
"eicon-error-404",
"eicon-theme-style",
"eicon-search-results",
"eicon-single-post",
"eicon-site-identity",
"eicon-theme-builder",
"eicon-download-bold",
"eicon-share-arrow",
"eicon-global-settings",
"eicon-user-preferences",
"eicon-lock",
"eicon-export-kit",
"eicon-import-kit",
"eicon-lottie",
"eicon-products-archive",
"eicon-single-product",
"eicon-disable-trash-o",
"eicon-single-page",
"eicon-wordpress-light",
"eicon-cogs-check",
"eicon-custom-css",
"eicon-global-colors",
"eicon-globe",
"eicon-typography-1",
"eicon-background",
"eicon-device-responsive",
"eicon-device-wide",
"eicon-code-highlight",
"eicon-video-playlist",
"eicon-download-kit",
"eicon-kit-details",
"eicon-kit-parts",
"eicon-kit-upload",
"eicon-kit-plugins",
"eicon-kit-upload-alt",
"eicon-hotspot",
"eicon-paypal-button",
"eicon-shape",
"eicon-wordart",
"eicon-checkout",
"eicon-container",
"eicon-flip",
"eicon-info",
"eicon-my-account",
"eicon-purchase-summary",
"eicon-page-transition",
"eicon-spotify",
"eicon-stripe-button",
"eicon-woo-settings",
"eicon-woo-cart",
"eicon-eye",
"eicon-elementor-square"
]

render(<App icons={icons}/>, document.getElementById('root'));
